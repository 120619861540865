// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCuetyi8x7jQmqWkc7Yc90nbm9yCOkXGVw",
  authDomain: "onkarsugar-1e9b8.firebaseapp.com",
  projectId: "onkarsugar-1e9b8",
  storageBucket: "onkarsugar-1e9b8.appspot.com",
  messagingSenderId: "606622501734",
  appId: "1:606622501734:web:09046b456f39b2b47c56a7",
  measurementId: "G-ZSJG3RVY0L",
  databaseURL : "https://onkarsugar-1e9b8-default-rtdb.asia-southeast1.firebasedatabase.app/"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);