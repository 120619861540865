import React from "react";
import { Link } from "react-router-dom";

function Cookies() {
  return (
    <div className="privacy-parent parent">
      <div className="privacy-cont cont">
        <h2 className="top-tag">Terms and Conditions</h2>

        <p>Welcome to onkarsugars!</p>

        <p>
          These terms and conditions outline the rules and regulations for the
          use of onkarsakhar's Website, located at <br />
          <Link to="https://onkarsugars.com">https://onkarsugars.com</Link>
        </p>

        <p>
          By accessing this website we assume you accept these terms and
          conditions. Do not continue to use onkarsugars if you do not agree to
          take all of the terms and conditions stated on this page.
        </p>

       

        <h3>Cookies</h3>

        <p>
          We employ the use of cookies. By accessing onkarsugars, you agreed to
          use cookies in agreement with the onkarsakhar's Privacy Policy.{" "}
        </p>

        <p>
          Most interactive websites use cookies to let us retrieve the user's
          details for each visit. Cookies are used by our website to enable the
          functionality of certain areas to make it easier for people visiting
          our website. Some of our affiliate/advertising partners may also use
          cookies.
        </p>

        <h3>License</h3>

        <p>
          Unless otherwise stated, onkarsakhar and/or its licensors own the
          intellectual property rights for all material on onkarsugars. All
          intellectual property rights are reserved. You may access this from
          onkarsugars for your own personal use subjected to restrictions set in
          these terms and conditions.
        </p>

        <p>You must not:</p>
        <ul>
          <li>Republish material from onkarsugars</li>
          <li>Sell, rent or sub-license material from onkarsugars</li>
          <li>Reproduce, duplicate or copy material from onkarsugars</li>
          <li>Redistribute content from onkarsugars</li>
        </ul>

      

        <p>You warrant and represent that:</p>

        <ul>
          <li>
            You are entitled to post the Comments on our website and have all
            necessary licenses and consents to do so;
          </li>
          <li>
            The Comments do not invade any intellectual property right,
            including without limitation copyright, patent or trademark of any
            third party;
          </li>
          <li>
            The Comments do not contain any defamatory, libelous, offensive,
            indecent or otherwise unlawful material which is an invasion of
            privacy
          </li>
          <li>
            The Comments will not be used to solicit or promote business or
            custom or present commercial activities or unlawful activity.
          </li>
        </ul>

        <p>
          You hereby grant onkarsakhar a non-exclusive license to use,
          reproduce, edit and authorize others to use, reproduce and edit any of
          your Comments in any and all forms, formats or media.
        </p>

       

        <p>Approved organizations may hyperlink to our Website as follows:</p>

        <ul>
          <li>By use of our corporate name; or</li>
          <li>By use of the uniform resource locator being linked to; or</li>
          <li>
            By use of any other description of our Website being linked to that
            makes sense within the context and format of content on the linking
            party's site.
          </li>
        </ul>

        <p>
          No use of onkarsakhar's logo or other artwork will be allowed for
          linking absent a trademark license agreement.
        </p>

        
        <h3>Content Liability</h3>

        <p>
          We shall not be hold responsible for any content that appears on your
          Website. You agree to protect and defend us against all claims that is
          rising on your Website. No link(s) should appear on any Website that
          may be interpreted as libelous, obscene or criminal, or which
          infringes, otherwise violates, or advocates the infringement or other
          violation of, any third party rights.
        </p>

        <h3>Reservation of Rights</h3>

        <p>
          We reserve the right to request that you remove all links or any
          particular link to our Website. You approve to immediately remove all
          links to our Website upon request. We also reserve the right to amen
          these terms and conditions and it's linking policy at any time. By
          continuously linking to our Website, you agree to be bound to and
          follow these linking terms and conditions.
        </p>

        <h3>Removal of links from our website</h3>

        <p>
          If you find any link on our Website that is offensive for any reason,
          you are free to contact and inform us any moment. We will consider
          requests to remove links but we are not obligated to or so or to
          respond to you directly.
        </p>

        <p>
          We do not ensure that the information on this website is correct, we
          do not warrant its completeness or accuracy; nor do we promise to
          ensure that the website remains available or that the material on the
          website is kept up to date.
        </p>

        <h3>Disclaimer</h3>

        <p>
          To the maximum extent permitted by applicable law, we exclude all
          representations, warranties and conditions relating to our website and
          the use of this website. Nothing in this disclaimer will:
        </p>

        <ul>
          <li>
            limit or exclude our or your liability for death or personal injury;
          </li>
          <li>
            limit or exclude our or your liability for fraud or fraudulent
            misrepresentation;
          </li>
          <li>
            limit any of our or your liabilities in any way that is not
            permitted under applicable law; or
          </li>
          <li>
            exclude any of our or your liabilities that may not be excluded
            under applicable law.
          </li>
        </ul>

        <p>
          The limitations and prohibitions of liability set in this Section and
          elsewhere in this disclaimer: (a) are subject to the preceding
          paragraph; and (b) govern all liabilities arising under the
          disclaimer, including liabilities arising in contract, in tort and for
          breach of statutory duty.
        </p>

        <p>
          As long as the website and the information and services on the website
          are provided free of charge, we will not be liable for any loss or
          damage of any nature.
        </p>
      </div>
    </div>
  );
}

export default Cookies;
