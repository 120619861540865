import React from "react";
import "../Styles/error.scss";
import { Link } from "react-router-dom";

function Erropage() {
  return (
    <>
      <div className="error-parent parent">
        <div className="error-cont cont">
          <h2>Oops Page Not Found !</h2>
        </div>
      </div>
      <div className="img-parent parent">
       <Link className="btn" to="/">Back To Home</Link>
      </div>
    </>
  );
}

export default Erropage;
