import React, { useEffect } from "react";
import "../Styles/factories.scss";

import factoryImg from "../Assets/raw/bg5.webp";
import TwoColSec from "../Comps/TwoColSec";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// aos
import AOS from "aos";
import "aos/dist/aos.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function About() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className="about-sec bg-img-cover parent">
        <div className="overlay">
          <div className="about-cont cont">
            <h1>Our Factories </h1>
          </div>
        </div>
      </div>

      <TwoColSec
        background=""
        row=""
        bg={factoryImg}
        subheading="Onkar Sakhar Karkhana Pvt. Ltd.
        "
        subdescription="Onkar Sakhar Karkhana Pvt Ltd., situated in Chandapuri, Tal. Malshiras, Dist. Solapur, is a private limited Indian non-government company incorporated in India. Located near Malshiras, the company operates in an area abundant with irrigation facilities, particularly one-side canal irrigation, ensuring ample water availability conducive to sugarcane cultivation. Leveraging the favourable agricultural landscape, Onkar Sakhar Karkhana Pvt Ltd. specialises in sugar production, boasting an installed crushing capacity of 2500 TCD (Tons of Cane Crushed per Day). In response to the abundant supply of sugarcane in its operational area, the company has undertaken modernization initiatives, enhancing its sugar plant capabilities to achieve an impressive daily crushing capacity of 7000 metric tons. This strategic move underscores the company's commitment to operational excellence and maximising productivity in the sugar industry.


                "
        btn=""
      />

      {/* ...........//Personality Section//............. */}

      <div className="personality factory-section parent">
      <div className="overlay-img overlay-img-for-factory bg-img-cover">
   <div className="personality-container cont">
          <h2 data-aos="fade-up" data-aos-delay="0" data-aos-duration="900">
            FACTORIES
          </h2>
          <p data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
            Discover the heart of innovation and productivity at Onkar Sakhar
            Karkhana Pvt Ltd. Our factories, strategically located in key
            agricultural regions, are dedicated to excellence in sugar
            production and sustainable energy generation.
          </p>

       
          
              <div className="personality_twobox_cont">
                <div
                  className="personality_img factories-img-first  bg-img-cover"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                ></div>
                <div
                  className="personality_content"
                  data-aos="fade-up"
                  data-aos-delay="0"
                  data-aos-duration="1000"
                >
                  <h3>ONKAR SAKHAR KARKHANA PVT LTD - UNIT 1 </h3>
                  <p>
                    This unit boasts a sugar daily crushing capacity of 7500
                    TDC, accompanied by a distillery producing 150 KLPD and a
                    cogeneration facility generating 20 MW of power. Situated in
                    Chandapuri, Tal. Malshiras, Dist. Solapur, the unit also
                    hosts a bio CNG project with a capacity of 12 TPH, a compost
                    fertiliser project producing 200 MT, and an Indian Oil
                    petrol pump.
                  </p>
                </div>
              </div>
      
              <div className="personality_twobox_cont"  style={{flexDirection:"row-reverse" }}>
                <div
                  className="personality_img factories-img-second  bg-img-cover"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                ></div>
                <div className="personality_content"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-duration="1000">
                  <h3>Onkar SAKHAR KARKHANA PVT LTD - UNIT 2</h3>
                  <p>
                    With a sugar daily crushing capacity of 3500 TDC, Unit 2
                    houses a distillery generating 150 KLPD and a compost
                    fertiliser project producing 200 MT. Located in Ambulga
                    (BU), Tal. Nilanga, Dist. Latur, the unit also features an
                    Indian Oil petrol pump and a bio CNG project with a capacity
                    of 12 TPH.
                  </p>
                </div>
              </div>
          
              <div className="personality_twobox_cont">
                <div
                  className="personality_img factories-img-third  bg-img-cover"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                ></div>
                <div className="personality_content"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-duration="1000">
                  <h3>ONKAR SUGAR AND DISTILLERY POWER PVT. LTD.</h3>
                  <p>
                    This unit showcases a sugar daily crushing capacity of 3500
                    TDC and a cogeneration facility producing 8.5 MW of power.
                    Positioned in Farale, Pitch Tal. Radhanagari, Dist.
                    Kolhapur, it contributes significantly to power generation
                    in the region.
                  </p>
                </div>
              </div>
       
              <div className="personality_twobox_cont" style={{flexDirection:"row-reverse" }}>
                <div
                  className="personality_img factories-img-fourth  bg-img-cover"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                ></div>
                <div className="personality_content"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-duration="1000">
                  <h3>GAURI SUGAR AND DISTILLERY PVT. LTD.</h3>
                  <p>
                    With an impressive sugar daily crushing capacity of 10,000
                    TDC, this unit hosts a distillery producing 300 KLPD and a
                    cogeneration facility generating 40 MW of power. Located in
                    Hiradgaon, Tal. Shrigonda, Dist. Ahamadnagar, it also
                    operates a bio CNG project with a capacity of 15 TPH, a
                    compost fertiliser project yielding 200 MT, and an Indian
                    Oil petrol pump.
                  </p>
                </div>
              </div>
          
              <div className="personality_twobox_cont">
                <div
                  className="personality_img factories-img-fifth  bg-img-cover"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                ></div>
                <div className="personality_content"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-duration="1000">
                  <h3>ONKAR POWER CORPORATION PVT. LTD.</h3>
                  <p>
                    Situated in Tadaval, Tal. Akkalkot, Dist. Solapur, this unit
                    features a sugar daily crushing capacity of 5,000 TDC and a
                    co-generation facility generating 20 MW of power.
                    Additionally, it hosts an Indian Oil petrol pump,
                    contributing to the region's energy needs.
                  </p>
                </div>
              </div>
            
        
        </div>
   </div>
       
       
      </div>

      {/* <div className="about-section-para parent">
        <div className="about-section-para-cont cont">
          <div className="about-title">
            <h3>title</h3>
          </div>
          <div className="about-section-para-list">
            <div className="about-section-para-list-left">
              <p>
                Established amidst the lush landscapes of Chandapuri, Tal.
                Malshiras, Dist. Solapur, Onkar Sakhar Karkhana Pvt Ltd. stands
                as a testament to vision and enterprise. Born from the fertile
                soil of ambition and nurtured by the waters of dedication, our
                journey began with a profound understanding of the agricultural
                potential nestled in the vicinity of Malshiras. Situated
                adjacent to Malshiras, our venture benefits from an abundance of
                irrigation facilities, notably the
                {accordian && (
                  <span>
                    one-side canal irrigation system, which bestows upon us the
                    gift of plentiful water—a fundamental prerequisite for
                    flourishing sugarcane cultivation. It was amidst this
                    backdrop of natural abundance that the seeds of Onkar Sakhar
                    Karkhana Pvt Ltd. were sown. Driven by a deep-rooted
                    commitment to harnessing the agricultural riches of the
                    region, we embarked on our quest to specialise in sugar
                    production. With an initial installed crushing capacity of
                    2500 TCD Tons of Cane Crushed per Day, we set out to weave a
                    story of innovation and growth in the annals of the sugar
                    industry. Through strategic initiatives and investments, we
                    bolstered our sugar plant's capabilities, culminating in a
                    remarkable achievement—a daily crushing capacity of 7000
                    metric tons.
                  </span>
                )}
                <Link onClick={() => setAccordian(!accordian)}> read more</Link>
              </p>
            </div>
            <div className="about-section-para-list-right">
              <p>
                This transformation wasn't merely about expanding our horizons;
                it was a testament to our unwavering commitment to operational
                excellence and the relentless pursuit of productivity. Each step
                taken towards modernization echoed our dedication to not just
                meet but exceed industry standards, thereby solidifying our
                position as a beacon of success in the sugar manufacturing
                landscape. Today, we take pride in the fact that every drop of
                sweat, every moment of toil, has
                {accordianSecond && (
                  <span>
                    contributed to shaping Onkar Sakhar Karkhana Pvt Ltd. into
                    what it is—a symbol of resilience, ingenuity, and unwavering
                    dedication to the craft of sugar production. Our story is
                    one of triumph over challenges, of growth amidst adversity,
                    and of a relentless pursuit of excellence—a story that
                    continues to unfold, chapter by chapter, as we march
                    steadfastly towards a future adorned with even greater
                    achievements.
                  </span>
                )}{" "}
                <Link onClick={() => setAccordianSecond(!accordianSecond)}>
                  read more
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="about-section-counters parent">
        <div className="about-section-cont cont">
          <div className="counter-first  counters">
            <div className="count-div">
              <h3>
                <CountUp end={29500} /> <span className="spantext">TCD</span>
              </h3>
            </div>
            <p>Daily Crushing Capacity</p>
          </div>
          <div className="counter-second  counters">
            <div className="count-div">
            <h3>
              <CountUp end={2127} /> <span className="spantext">Crore</span>
            </h3>
            </div>
            <p>Total Turnove</p>
          </div>
          <div className="counter-third  counters">
           <div className="count-div">
           <h3>
              <CountUp end={30} /> <span className="spantext">LACS</span>
            </h3>
           </div>
            <p>Godown Capacity</p>
          </div>
          <div className="counter-fourth  counters">
            <div className="count-div">
            <h3>
              <CountUp end={3470} /> <span className="spantext"></span>
            </h3>
            </div>
            <p>Total Employeesy</p>
          </div>
          <div className="counter-fifth  counters">
            <div className="count-div">
            <h3>
              <CountUp end={492400} />{" "}
            </h3>
            </div>
            <p>Total Farmers Engaged</p>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default About;
