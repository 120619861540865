import React, { useState,useEffect } from "react";
import "../Styles/about.scss";
import img from "../Assets/raw/bg5.webp"

import img2 from "../Assets/raw/bg2.webp"
import TwoColSec from "../Comps/TwoColSec";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// aos
import AOS from "aos";
import "aos/dist/aos.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function About() {

  useEffect(() => {
    AOS.init();
  },[]);
  return (
    <>
      <div className="about-sec bg-img-cover parent">
        <div className="overlay">
          <div className="about-cont cont">
            <h1>About Us </h1>
          </div>
        </div>
      </div>

      <TwoColSec
        background=""

        row=""
        bg={img}
        subheading="OUR STORY "
        subdescription="Established amidst the lush landscapes of Chandapuri, Tal. Malshiras, Dist. Solapur, Onkar Sakhar Karkhana Pvt Ltd. stands as a testament to vision and enterprise. Born from the fertile soil of ambition and nurtured by the waters of dedication, our journey began with a profound understanding of the agricultural potential nestled in the vicinity of Malshiras.
        Situated adjacent to Malshiras, our venture benefits from an abundance of irrigation facilities, notably the one-side canal irrigation system, which bestows upon us the gift of plentiful water—a fundamental prerequisite for flourishing sugarcane cultivation. It was amidst this backdrop of natural abundance that the seeds of Onkar Sakhar Karkhana Pvt Ltd. were sown.
        
        Driven by a deep-rooted commitment to harnessing the agricultural riches of the region, we embarked on our quest to specialise in sugar production. With an initial installed crushing capacity of 2500 TCD (Tons of Cane Crushed per Day), we set out to weave a story of innovation and growth in the annals of the sugar industry.
        


                "
        btn=""
      />

      <TwoColSec
        background=""
   
        row="row-reverse"
        bg={img2}
        subheading=""
        subdescription="Through strategic initiatives and investments, we bolstered our sugar plant's capabilities, culminating in a remarkable achievement—a daily crushing capacity of 7000 metric tons. and This transformation wasn't merely about expanding our horizons; it was a testament to our unwavering commitment to operational excellence and the relentless pursuit of productivity. Each step taken towards modernization echoed our dedication to not just meet but exceed industry standards, thereby solidifying our position as a beacon of success in the sugar manufacturing landscape.

        Today, we take pride in the fact that every drop of sweat and every moment of toil, has contributed to shaping Onkar Sakhar Karkhana Pvt Ltd. into what it is—a symbol of resilience, ingenuity, and unwavering dedication to the craft of sugar production. Our story is one of triumph over challenges, of growth amidst adversity, and of a relentless pursuit of excellence—a story that continues to unfold, chapter by chapter, as we march steadfastly towards a nationwide expansion adorned with even greater achievements.
        
                


                "
        btn=""
      />

      {/* ...........//Personality Section//............. */}

      <div className="personality parent">
        <div className="overlay-img bg-img-cover">


        <div className="personality-container cont">
          <h2 data-aos="fade-up" data-aos-delay="0" data-aos-duration="900">
            OUR PROJECTS
          </h2>
          <p data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
            The projects undertaken by our industry reflect our commitment to innovation, sustainability, and community development, all woven into the fabric of our operations. Here's an overview of our noteworthy projects:
          </p>

          <Swiper
           paceBetween={30}
           centeredSlides={true}
           autoplay={{
             delay: 6500,
             disableOnInteraction: false,
           }}
           pagination={{
             clickable: true,
           }}
           navigation={true}
           modules={[Autoplay, Pagination, Navigation]}
           className="mySwiper"
          >
            <SwiperSlide>
              <div className="personality_twobox_cont">
                <div className="personality_img personality_img-1 bg-img-cover" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000"></div>
                <div className="personality_content" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">

                  <h3>Distillery Project - 600 KLPD</h3>
                  <p>Our distillery project, boasting a capacity of 600 KLPD (Kilolitres per Day), stands as a testament to our dedication to diversification and value addition. Through this venture, we leverage state-of-the-art technology to produce high-quality alcohol, catering to diverse industrial and commercial needs while ensuring optimal resource utilisation and environmental responsibility.</p>

                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="personality_twobox_cont">
                <div className="personality_img Cogeneration-img bg-img-cover" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000"></div>
                <div className="personality_content" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">

                  <h3>
                    Cogeneration Project - 88.50 MW
                  </h3>
                  <p>Our cogeneration project, generating an impressive 88.50 MW (Megawatts) of power, epitomises our commitment to sustainability and self-sufficiency. By harnessing the power of renewable resources and utilising waste by-products from our operations, we not only meet our energy needs but also contribute surplus electricity to the grid, fostering a greener, more sustainable future.</p>

                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="personality_twobox_cont">
                <div className="personality_img Bio-img bg-img-cover" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000"></div>
                <div className="personality_content">

                  <h3>
                  Bio CNG - 36 TPH
                  </h3>
                  <p>In line with our ethos of embracing renewable energy sources, our Bio CNG project, with a capacity of 36 TPH (Tons per Hour), signifies our dedication to reducing carbon emissions and promoting environmental conservation. By converting organic waste into clean, renewable energy, we play a pivotal role in mitigating climate change and fostering a cleaner, greener tomorrow.</p>

                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="personality_twobox_cont">
                <div className="personality_img  Compost-img bg-img-cover" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000"></div>
                <div className="personality_content">

                  <h3>
                  Compost Fertilizer - 600 MT
                  </h3>
                  <p>Our compost fertiliser project, producing 600 MT (Metric Tons) of organic fertiliser, underscores our commitment to sustainable agriculture and soil health. By recycling organic waste from our operations and neighbouring communities, we not only minimise waste but also enrich the soil with nutrient-rich compost, empowering farmers and promoting eco-friendly agricultural practices.</p>

                </div>
              </div>
            </SwiperSlide>

          </Swiper>
        </div>
        </div>
      </div>


      {/* <div className="about-section-para parent">
        <div className="about-section-para-cont cont">
          <div className="about-title">
            <h3>title</h3>
          </div>
          <div className="about-section-para-list">
            <div className="about-section-para-list-left">
              <p>
                Established amidst the lush landscapes of Chandapuri, Tal.
                Malshiras, Dist. Solapur, Onkar Sakhar Karkhana Pvt Ltd. stands
                as a testament to vision and enterprise. Born from the fertile
                soil of ambition and nurtured by the waters of dedication, our
                journey began with a profound understanding of the agricultural
                potential nestled in the vicinity of Malshiras. Situated
                adjacent to Malshiras, our venture benefits from an abundance of
                irrigation facilities, notably the
                {accordian && (
                  <span>
                    one-side canal irrigation system, which bestows upon us the
                    gift of plentiful water—a fundamental prerequisite for
                    flourishing sugarcane cultivation. It was amidst this
                    backdrop of natural abundance that the seeds of Onkar Sakhar
                    Karkhana Pvt Ltd. were sown. Driven by a deep-rooted
                    commitment to harnessing the agricultural riches of the
                    region, we embarked on our quest to specialise in sugar
                    production. With an initial installed crushing capacity of
                    2500 TCD Tons of Cane Crushed per Day, we set out to weave a
                    story of innovation and growth in the annals of the sugar
                    industry. Through strategic initiatives and investments, we
                    bolstered our sugar plant's capabilities, culminating in a
                    remarkable achievement—a daily crushing capacity of 7000
                    metric tons.
                  </span>
                )}
                <Link onClick={() => setAccordian(!accordian)}> read more</Link>
              </p>
            </div>
            <div className="about-section-para-list-right">
              <p>
                This transformation wasn't merely about expanding our horizons;
                it was a testament to our unwavering commitment to operational
                excellence and the relentless pursuit of productivity. Each step
                taken towards modernization echoed our dedication to not just
                meet but exceed industry standards, thereby solidifying our
                position as a beacon of success in the sugar manufacturing
                landscape. Today, we take pride in the fact that every drop of
                sweat, every moment of toil, has
                {accordianSecond && (
                  <span>
                    contributed to shaping Onkar Sakhar Karkhana Pvt Ltd. into
                    what it is—a symbol of resilience, ingenuity, and unwavering
                    dedication to the craft of sugar production. Our story is
                    one of triumph over challenges, of growth amidst adversity,
                    and of a relentless pursuit of excellence—a story that
                    continues to unfold, chapter by chapter, as we march
                    steadfastly towards a future adorned with even greater
                    achievements.
                  </span>
                )}{" "}
                <Link onClick={() => setAccordianSecond(!accordianSecond)}>
                  read more
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="about-section-counters parent">
        <div className="about-section-cont cont">
          <div className="counter-first  counters">
            <div className="count-div">
              <h3>
                <CountUp end={29500} /> <span className="spantext">TCD</span>
              </h3>
            </div>
            <p>Daily Crushing Capacity</p>
          </div>
          <div className="counter-second  counters">
            <div className="count-div">
            <h3>
              <CountUp end={2127} /> <span className="spantext">Crore</span>
            </h3>
            </div>
            <p>Total Turnove</p>
          </div>
          <div className="counter-third  counters">
           <div className="count-div">
           <h3>
              <CountUp end={30} /> <span className="spantext">LACS</span>
            </h3>
           </div>
            <p>Godown Capacity</p>
          </div>
          <div className="counter-fourth  counters">
            <div className="count-div">
            <h3>
              <CountUp end={3470} /> <span className="spantext"></span>
            </h3>
            </div>
            <p>Total Employeesy</p>
          </div>
          <div className="counter-fifth  counters">
            <div className="count-div">
            <h3>
              <CountUp end={492400} />{" "}
            </h3>
            </div>
            <p>Total Farmers Engaged</p>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default About;
