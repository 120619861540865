import React, {useEffect} from "react";
import "../Styles/products.scss";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";


// aos
import AOS from "aos";
import "aos/dist/aos.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Counter from "../Comps/Counter";

function Products() {
  useEffect(() => {
    AOS.init();
  },[]);
  
  return (
    <>
      <div className="products-sec bg-img-cover parent">
        <div className="overlay">
          <div className="products-cont cont">
            <h1> Our Products </h1>
          </div>
        </div>
      </div>
      <div className="products-parent parent">
        <div className="products-cont cont">
        <h2 data-aos="fade-up" data-aos-delay="0" data-aos-duration="900">
          Products
          </h2>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 65000000000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide className="swiperslide-products">
              <div className="swiperslide-left" data-aos="fade-up" data-aos-delay="0" data-aos-duration="900">
                <div className="product-img bg-img-cover"></div>
              </div>
              <div className="swiperslide-right" data-aos="fade-up" data-aos-delay="0" data-aos-duration="900">
                <h2>sugar</h2>
                <p>
                  Onkar Sakhar Karkhana Pvt Ltd specialises in sugar production,
                  leveraging its favourable agricultural landscape and
                  modernised sugar plant capabilities. With a daily crushing
                  capacity of 2500 TCD (Tons of Cane Crushed per Day), the
                  company ensures the highest quality sugar for various
                  industrial and consumer needs.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiperslide-products">
              <div className="swiperslide-left">
                <div className="product-img distlillery-img bg-img-cover"></div>
              </div>
              <div className="swiperslide-right">
                <h2>Distillery</h2>
                <p>
                  The distillery projects under Onkar Sakhar Karkhana Pvt Ltd
                  encompass multiple units, each equipped to produce
                  high-quality alcohol. With capacities ranging from 150 KLPD to
                  300 KLPD, these distilleries cater to diverse industrial
                  applications, contributing to the company's portfolio of
                  value-added products.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiperslide-products">
              <div className="swiperslide-left">
                <div className="product-img Cogeneration-img bg-img-cover"></div>
              </div>
              <div className="swiperslide-right">
                <h2>Cogeneration</h2>
                <p>
                  Onkar Sakhar Karkhana Pvt Ltd and its subsidiary units are
                  committed to sustainable energy production through
                  cogeneration facilities. With capacities ranging from 8.5 MW
                  to 40 MW, these facilities generate clean, renewable energy
                  while maximising the utilisation of resources, contributing
                  significantly to the region's power needs.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="swiperslide-products">
              <div className="swiperslide-left">
                <div className="product-img Fertiliser-img bg-img-cover"></div>
              </div>
              <div className="swiperslide-right">
                <h2>Fertiliser</h2>
                <p>
                  Emphasising environmental sustainability and agricultural
                  productivity, Onkar Sakhar Karkhana Pvt Ltd ventures into
                  compost fertiliser production. With projects yielding up to
                  200 MT of organic fertiliser, the company supports
                  eco-friendly farming practices, enriching soil health and
                  promoting sustainable agriculture.
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <Counter/>
    </>
  );
}

export default Products;
