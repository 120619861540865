import React from 'react';
import "../Styles/counter.scss"
import CountUp from 'react-countup';
import { GiSugarCane } from "react-icons/gi";   
import { FaSackDollar } from "react-icons/fa6";
import { BiSolidFactory } from "react-icons/bi";
import { IoPeopleSharp } from "react-icons/io5";
import { GiFarmer } from "react-icons/gi";

function Counter() {
  return (
    <>
      <div className="counter-parent parent">
     <div className='overlay-img bg-img-cover'>
     <div className="counter-cont cont">
        <div className="counter-tag">
          <h2>Our Produce In Numbers</h2>
        </div>
        <div className="counter-number">
               <div className="counter-list">
                <div className="counter-list-top">
                  <div className="counter-round">
                  <div className="counting-number">
                      <h3><CountUp end={29500} /></h3>
                      </div>
                      <h5>TCD</h5>
                  </div>
                </div>
                <div className="counter-list-bottom">
                  <p>Daily Crushing 
                   Capacity</p>
                </div>
               </div>
               <div className="counter-list">
                <div className="counter-list-top">
                  <div className="counter-round">
                  <div className="counting-number">
                      <h3><CountUp end={600 } /></h3>
                      </div>
                      <h5>KLPD</h5>
                  </div>
                </div>
                <div className="counter-list-bottom">
                  <p>Distillery Project Capacity</p>
                </div>
               </div>
               <div className="counter-list">
                <div className="counter-list-top">
                  <div className="counter-round">
                  <div className="counting-number">
                      <h3><CountUp end={88.50} /></h3>
                      </div>
                      <h5>MW</h5>
                  </div>
                </div>
                <div className="counter-list-bottom">
                  <p>Cogeneration Project Capacity</p>
                </div>
               </div>
               <div className="counter-list">
                <div className="counter-list-top">
                  <div className="counter-round">
                  <div className="counting-number">
                      <h3><CountUp end={ 36 } /></h3>
                      </div>
                      <h5>TPH</h5>
                  </div>
                </div>
                <div className="counter-list-bottom">
                  <p>Bio CNG 
                   Capacity</p>
                </div>
               </div>
               <div className="counter-list">
                <div className="counter-list-top">
                  <div className="counter-round">
                  <div className="counting-number">
                      <h3><CountUp end={600} /></h3>
                      </div>
                      <h5>MT</h5>
                  </div>
                </div>
                <div className="counter-list-bottom">
                  <p>Compost Fertiliser Production</p>
                </div>
               </div>
               
        </div>
      </div>
     </div>

      </div>
    </>
  )
}

export default Counter
