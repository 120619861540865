import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";

export default function HeroSection() {
  return (
    <>
      <div className="hero-section">
        <Swiper
          spaceBetween={0}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide className="bg-img-cover slide-image">
            <div className="overlay">
              <h1>
              Sweeter Horizons, Bolder Dreams
              </h1>
              <Link className="btn" to="/products"> Get Started </Link>
            </div>
          </SwiperSlide>
          <SwiperSlide className="bg-img-cover slide-image">
            <div className="overlay">
              <h1>
              Cultivating A Legacy of Excellence

              </h1>
              <Link className="btn" to="/contact"> Contact Us</Link>
            </div>
          </SwiperSlide>
          <SwiperSlide className="bg-img-cover slide-image">
            <div className="overlay">
              <h1>
              Sweetness Beyond Boundaries
              </h1>
              <Link className="btn" to="/about"> Know More</Link>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}
